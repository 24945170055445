import React, { useState } from "react";
import "./ContactForm.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "information",
    message: "",
    captcha: false,
  });

  const [status, setStatus] = useState({ message: "", type: "" });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/index.php?path=contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.status === 1) {
        setStatus({
          message: "Votre message a été envoyé avec succès ! Nous vous répondrons dans les plus brefs délais.",
          type: "success",
        });
        setFormData({ name: "", email: "", subject: "information", message: "", captcha: false });
      } else {
        setStatus({
          message: "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Erreur:", error);
      setStatus({
        message: "Une erreur est survenue lors de l'envoi du message. Veuillez réessayer.",
        type: "error",
      });
    }
  };

  return (
    <section className="contact-section">
      <div className="contact-container">
        <h2 className="page-title text-center">Contactez-nous</h2>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group large">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Votre nom"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Votre email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Objet du message</label>
            <select
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            >
              <option value="information">Demande d'information(s)</option>
              <option value="partnership">Proposition de partenariat</option>
              <option value="support">Support technique</option>
              <option value="other">Autre</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="textarea">Message</label>
            <textarea
              id="textarea"
              name="message"
              placeholder="Votre message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="captcha-group">
            <input
              type="checkbox"
              id="captcha"
              className="captcha-checkbox"
              name="captcha"
              checked={formData.captcha}
              onChange={handleChange}
              required
            />
            <label htmlFor="captcha" className="captcha-label">
              Je ne suis pas un robot et j'ai pris connaissance de la politique de confidentialité.
            </label>
          </div>
          <button type="submit" className="submit-btn">Envoyer</button>
        </form>

        {status.message && (
          <div className={`status-message ${status.type}`}>{status.message}</div>
        )}
      </div>
    </section>
  );
};

export default ContactForm;
