import React from "react";
import "./About.css";
import GeminiGenerator from "../Components/GeminiGenerator/GeminiGenerator";

const About = () => {
  return (
    <GeminiGenerator/>
  );
};

export default About;
