

import React, { useState } from 'react';
import { generateWithGemini } from './services/gemini';
import { Loader2, AlertCircle, Globe, FileText, Package, Clock, Layout, Settings, Edit} from 'lucide-react';
import './GeminiGenerator.css';
import Select from 'react-select';

const contentTypes = [
  { id: 'article', label: 'Article de blog'},
  { id: 'product', label: 'Description produit'},
  { id: 'landing', label: 'Page de destination'},
];

const languages = [
  { id: 'fr', label: 'Français' },
  // { id: 'en', label: 'Anglais' },
  { id: 'es', label: 'Espagnol' },
];

const languageOptions = languages.map((lang) => ({
  value: lang.id,
  label: (
    <div className="option">
      {lang.label}
    </div>
  ),
}));

const typeOptions = contentTypes.map((type) => ({
  value: type.id,
  label: (
    <div className="option">
      {type.label}
    </div>
  ),
}));

const ErrorMessage = ({ message }) => (
  <div className="error-message">
    <AlertCircle className="icon" />
    <div>{message}</div>
  </div>
);

export default function GeminiGenerator() {
  const [request, setRequest] = useState({
    topic: '',
    language: '',
    type: 'article',
  });
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleGenerate = async () => {
    if (!request.topic || !request.language) {
      setError('Veuillez remplir tous les champs');
      return;
    }

    setLoading(true);
    setError(null);

    try {

      let prompt = ''; // Déclarez `prompt` ici

    // Sélection du prompt en fonction du type de contenu
    switch (request.type) {
      case 'article':
        prompt = `Écris-moi un texte optimisé pour le SEO adapté pour un article de blog à propos de : "${request.topic}" en ${request.language}. 
        Il doit inclure des mots clés et être conforme aux meilleures attentes en matière de SEO.
        Le contenu doit être engageant et informatif.`;
        break;

      case 'product':
        prompt = `Rédige une description produit optimisée pour le SEO à propos de : "${request.topic}", en ${request.language}.
        Le texte doit :
        - Captiver l'intérêt de l'utilisateur dès les premières phrases.
        - Mettre en valeur les caractéristiques clés et les avantages uniques du produit.
        - Inclure des mots-clés pertinents (directs et à longue traîne) pour améliorer le référencement.
        - Être structuré avec des titres et sous-titres pour une lecture fluide.
        - Inciter à l'achat avec un appel à l'action clair.
        - Assure-toi que le contenu est engageant, informatif et rédigé dans un style adapté au public cible.`;
        break;

      case 'landing':
        prompt = `Écris-moi un texte optimisé pour le SEO adapté pour une page de destination à propos de : "${request.topic}" en ${request.language}. 
        Il doit inclure des mots clés et être conforme aux meilleures attentes en matière de SEO.
        Le contenu doit être engageant et informatif.`;
        break;

      default:
        setError('Type de contenu non pris en charge.');
        return;
    }

      console.log(prompt);

      const result = await generateWithGemini(prompt);
      setContent(result);
    } catch (err) {
      setError('Erreur lors de la génération du contenu. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour parser et formater le texte généré
  const renderFormattedContent = (text) => {
    const lines = text.split('\n');
    const elements = lines.map((line, index) => {
      if (line.startsWith('## ')) {
        return <h2 key={index} className="title-2">{line.slice(3)}</h2>;
      } else if (line.startsWith('### ')) {
        return <h3 key={index} className="title-3">{line.slice(4)}</h3>;
      } else if (line.startsWith('- ')) {
        return <li key={index} className="list-item">{line.slice(2)}</li>;
      } else if (line.startsWith('**') && line.endsWith('**')) {
        return (
          <h4 key={index} className="bold-title">
            {line.slice(2, -2)}
          </h4>
        );
      } else if (line.trim() === '') {
        return null; // Ignore empty lines
      } else {
        // Ajouter une logique pour les mots-clés en *italique* et **gras**
        const formattedLine = line
          .split(/(\*\*.*?\*\*|\*.*?\*)/)
          .filter((chunk) => chunk !== '')
          .map((chunk, chunkIndex) => {
            if (chunk.startsWith('**') && chunk.endsWith('**')) {
              return (
                <strong key={`chunk-${index}-${chunkIndex}`}>
                  {chunk.slice(2, -2)}
                </strong>
              );
            } else if (chunk.startsWith('*') && chunk.endsWith('*')) {
              return (
                <em key={`chunk-${index}-${chunkIndex}`}>
                  {chunk.slice(1, -1)}
                </em>
              );
            } else {
              return chunk;
            }
          });

        return (
          <p key={index} className="paragraph">
            {formattedLine}
          </p>
        );
      }
    });

    // Regrouper les listes dans des balises <ul>
    const wrappedElements = [];
    let currentList = null;

    elements.forEach((element, index) => {
      if (element && element.type === 'li') {
        if (!currentList) currentList = [];
        currentList.push(element);
      } else {
        if (currentList) {
          wrappedElements.push(
            <ul key={`list-${index}`} className="list">
              {currentList}
            </ul>
          );
          currentList = null;
        }
        if (element) wrappedElements.push(element);
      }
    });

    if (currentList) {
      wrappedElements.push(
        <ul key="last-list" className="list">
          {currentList}
        </ul>
      );
    }

    return wrappedElements;
  };

  return (
    <>
      <div className="container">
        <h2 className='page-title'>Créez du contenu de qualité en un clic avec notre générateur EASYO Writer</h2>
        <div className="description">
          <p>Découvrez <em>EASYO Writer</em>, votre assistant de rédaction pour créer des contenus engageants, optimisés pour le SEO et adaptés à chaque besoins spécifiques. Que vous ayez besoin d’un article de blog captivant, d’une description produit percutante ou d’une page de destination qui convertit, notre générateur intelligent s’adapte à vos demandes.</p>
          {/* <p>De plus, il est entièrement configurable et adaptable à votre activité lorsqu'il est est implémenté sur votre solution spécifique.</p> */}
          {/* <p>Avec une interface simple et intuitive, vous pouvez personnaliser votre contenu en sélectionnant le type, la langue et le sujet. En quelques secondes, obtenez des textes professionnels et performants qui reflètent parfaitement votre vision.</p> */}
          <div className="features-list form-row">
            <div className='feature'>
              <p><FileText className="icon" width="45" height="45"/></p>
              <p><span>Besoin d’un <strong>article de blog captivant</strong> ? Notre outil vous aide à captiver vos lecteurs dès la première ligne.</span></p>
            </div>
            <div className='feature'>
              <p><Package className="icon" width="45" height="45"/></p>
              <p><span>Une <strong>description produit percutante</strong> ? Mettez en avant les points forts de vos produits de manière convaincante.</span></p>
            </div>
            <div className='feature'>
              <p><Layout className="icon"  width="45" height="45"/></p>
              <p><span>Une <strong>page de destination efficace</strong> ? Créez des textes qui convertissent et attirent l'attention.</span></p>
            </div>
          </div>
          
          <ul className="features-list">
            <li>
              <Settings className="icon" />
              <span>Entièrement <strong>configurable</strong> et adaptable à votre activité pour s'intégrer parfaitement à vos solutions.</span>
            </li>
            <li>
              <Edit className="icon" />
              <span>Avec une <strong>interface simple et intuitive</strong>, personnalisez le contenu en quelques clics : type, langue, sujet.</span>
            </li>
            <li>
              <Clock className="icon" />
              <span>En quelques secondes, obtenez des <strong>textes professionnels et performants</strong> qui reflètent votre vision.</span>
            </li>
          </ul>
          <p><strong>Essayez-le dès maintenant et transformez vos idées en contenus impactants !</strong></p>
        </div>
    
      </div>
      <div className="gemini-generator container">
        <h2 className="">Générer du contenu optimisé pour le SEO</h2>
        <div className="form-container">
          <input
            type="text"
            placeholder="Fournissez des détails sur l'article à écrire et des mots clés pertinents"
            value={request.topic}
            onChange={(e) => setRequest({ ...request, topic: e.target.value })}
            className="input"
          />

          <div className="form-row">
            <Globe className="icon icon-type" />
            <Select
              options={languageOptions}
              onChange={(selected) =>
                setRequest({ ...request, language: selected?.value || '' })
              }
              placeholder="Sélectionner une langue"
              className='select'
            />
            
            <FileText className="icon icon-type" />
            <Select
              options={typeOptions}
              onChange={(selected) =>
                setRequest({ ...request, type: selected?.value || '' })
              }
              placeholder="Sélectionner un type de contenu"
              className='select'
            />
          </div>

          <button onClick={handleGenerate} disabled={loading} className="button">
            {loading ? (
              <>
                <Loader2 className="spinner" />
                Génération en cours...
              </>
            ) : (
              'Générer'
            )}
          </button>

          {error && <ErrorMessage message={error} />}

          {content && (
            <div className="content">{renderFormattedContent(content)}</div>
          )}
        </div>
      </div>
    </>
  );
}
