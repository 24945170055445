import { GoogleGenerativeAI } from '@google/generative-ai';

// const API_KEY = process.env.VITE_GEMINI_API_KEY || '';
const API_KEY = 'AIzaSyDSWnRlw20m-hN3Zocom2-LEihI6Bq6MyY';
const genAI = new GoogleGenerativeAI(API_KEY);

export async function generateWithGemini(prompt) {
  try {
    const model = genAI.getGenerativeModel({ model: 'gemini-pro' });
    const result = await model.generateContent(prompt);
    const response = await result.response;
    return response.text();
  } catch (error) {
    console.error('Gemini API error:', error);
    throw new Error('GENERATION_FAILED');
  }
}
