import React, { useRef, useEffect } from 'react';
import './ServicesSection.css';

const ServicesSection = ({variant}) => {

  const servicesContainerRef = useRef(null);

  const scrollLeft = () => {
    if (servicesContainerRef.current) {
      servicesContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (servicesContainerRef.current) {
      servicesContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const services = [
    {
      id: 1,
      title: "Logo Design",
      description: "Création de logos personnalisés qui capturent l'essence de votre marque.",
      position: 1,
      active: true,
      image: "/assets/img/services/logo-clean-apt-white.jpg",
    },
    {
      id: 2,
      title: "Branding",
      description: "Développement d'une identité visuelle complète pour votre entreprise.",
      position: 2,
      active: true,
      image: "/assets/img/services/alice-colorz.png",
      // link: "branding.html"
    },
    {
      id: 3,
      title: "Conception site web sur mesure",
      description: "",
      position: 4,
      active: false,
      image: ""
    },
    {
      id: 4,
      title: "Développement web",
      description: "",
      position: 5,
      active: false,
      image: ""
    },
    {
      id: 5,
      title: "Conception applications mobiles",
      description: "",
      position: 7,
      active: false,
      image: ""
    },
    {
      id: 6,
      title: "Consulting",
      description: "",
      position: 6,
      active: false,
      image: ""
    },
    {
      id: 7,
      title: "Supports marketing",
      description: "Design de cartes de visite, flyers, et autres supports pour renforcer votre marque.",
      position: 3,
      active: true,
      image: "/assets/img/services/supports.jpg"
    }
  ];

  const ServiceCard = ({ service }) => {
    if (!service.active) return null;

    const cardContent = (
      <>
        <img 
          src={service.image} 
          alt={service.title}
          className=""
        />
        <div className="">
          <h3 className="">{service.title}</h3>
          <p className="">{service.description}</p>
        </div>
      </>
    );

    if (service.link) {
      return (
        <div className="service-card">
          <a href={service.link} className="">
            {cardContent}
          </a>
        </div>
      );
    }

    return (
      <div className="service-card">
        {cardContent}
      </div>
    );
  };

  const sortedServices = [...services].sort((a, b) => a.position - b.position);

  return (
    <section id="services" className={`services ${variant === "fullpage" ? "full" : ""}`}>
      <div className={`container ${variant === "homepage" ? "diapo" : ""}`}>
        <h2 className={`text-center ${variant === "fullpage" ? "page-title" : ""}`}>Nos Services</h2>

        {/* Conteneur pour les boutons de navigation et les cartes de services */}
        <div className="services-wrapper">
          {/* Bouton de navigation gauche, visible uniquement si variant est "homepage" */}
          {variant === "homepage" && (
            <div className="navigation-buttons">
              <button className="scroll-button" onClick={scrollLeft}>
                &#9664;
              </button>
            </div>
          )}

          <div className={`service-cards ${variant === "homepage" ? "horizontal-scroll" : ""}`} ref={servicesContainerRef}>
            {sortedServices.map(service => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </div>

          {/* Bouton de navigation droite, visible uniquement si variant est "homepage" */}
          {variant === "homepage" && (
            <div className="navigation-buttons">
              <button className="scroll-button" onClick={scrollRight}>
                &#9654;
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;